import { Hero } from '../Hero/Hero';

import HealthyEqualsHard from '@public/img/home-page/healthy-equals-hard.png';

export const ChangeYourMindHero = (): JSX.Element => (
  <Hero
    color="bg-teal-600"
    image={HealthyEqualsHard}
    includeNav={false}
    altText="Man smiling"
    title={
      <span className="text-blue">
        Think healthy equals hard?{' '}
        <span className="text-mono-white">Let us change your mind.</span>
      </span>
    }
  />
);
