import { useContext } from 'react';
import Image from 'next/image';
import Cookies from 'js-cookie';

import { useHasAnyMedicationAvailability } from '@hooks/useHasAnyMedicationAvailability';
import { MainContext } from '@components/context/MainProvider';
import { H6 } from '@common/headline';

import { TakeOurQuizButton } from '../Hero/TakeOurQuizCTA';

import MounjaroPen from '@public/img/home-page/mounjaro-vertical-pen.png';
import WegovyPen from '@public/img/home-page/wegovy-vertical-pen.png';
import Checkbox from '@public/img/medically-supported/checkbox.png';

type Props = {
  utmMedium: string;
  utmCampaign: string;
};

export const MedicationStockBanner = ({
  utmMedium,
  utmCampaign,
}: Props): JSX.Element | null => {
  const { medicationAvailability } = useContext(MainContext);

  const hasAnyMedicationAvailability = useHasAnyMedicationAvailability();

  if (!hasAnyMedicationAvailability) {
    return null;
  }

  const getUTMContent = () => {
    const homePageTestBucket = Cookies.get('bucket-home');

    if (homePageTestBucket === 'b') {
      return 'homepage_banner_med1b';
    } else if (homePageTestBucket === 'c') {
      return 'homepage_banner_med1c';
    }

    return 'homepage_banner_med';
  };

  return (
    <div className="flex justify-center px-4 pb-0 pt-3 md:pb-10 md:pt-6 lg:pb-0">
      <div className="flex h-[106px] w-fit flex-col items-center justify-center gap-4 rounded-2xl bg-white px-4 text-blue md:h-[48px] md:flex-row md:rounded-full md:pl-6">
        <div className="flex gap-x-4">
          {medicationAvailability.mounjaro.isAvailable && (
            <div className="flex items-center gap-x-4">
              <div className="relative bottom-[10px] hidden md:block">
                <Image
                  alt="Mounjaro Pen"
                  height={69}
                  src={MounjaroPen}
                  width={19}
                />
              </div>
              <div className="flex items-center gap-x-2">
                <Image alt="In Stock" height={21} src={Checkbox} width={21} />
                <H6>Mounjaro in stock</H6>
              </div>
            </div>
          )}
          {medicationAvailability.mounjaro.isAvailable &&
            medicationAvailability.wegovy.isAvailable && (
              <div className="block border-r border-salmon-800 md:hidden md:border-none" />
            )}
          {medicationAvailability.wegovy.isAvailable && (
            <div className="flex items-center gap-x-4">
              <div className="relative bottom-[11px] hidden md:block">
                <Image
                  alt="Wegovy Pen"
                  height={70}
                  src={WegovyPen}
                  width={19}
                />
              </div>
              <div className="flex items-center gap-x-2">
                <Image alt="In Stock" height={21} src={Checkbox} width={21} />
                <H6>Wegovy in stock</H6>
              </div>
            </div>
          )}
        </div>
        <div>
          <TakeOurQuizButton
            colour="NavyBlue"
            ctaText="Check my eligibility"
            isGlp1
            isSmall
            location="Homepage Medication Stock Banner"
            type="Primary"
            utmCampaign={utmCampaign}
            utmMedium={utmMedium}
            utmSource="organic"
            utmContent={getUTMContent()}
          />
        </div>
      </div>
    </div>
  );
};
