import { BigNumber } from '@common/BigNumber';
import { BodyRegular } from '@common/body';
import { SeparatedGrid } from './SeparatedGrid';

export const Positives123 = (): JSX.Element => (
  <div className="min-w-full bg-blue pb-8 pt-12 text-center lg:pb-[113px] lg:pt-[103px] lg:text-left">
    <SeparatedGrid
      content={[
        <div
          key="1"
          className="flex flex-col text-teal lg:h-[322px] lg:flex-row lg:items-center"
        >
          <BigNumber>1</BigNumber>
          <div className="mx-auto text-mono-white lg:mx-[40px] lg:my-auto lg:max-w-[224px]">
            <BodyRegular>
              Diets fail because they tell you{' '}
              <span className="text-teal">what</span> to eat, rather than
              teaching you <span className="text-teal">why</span> you eat and{' '}
              <span className="text-teal">how</span> to change your eating
              habits.
            </BodyRegular>
          </div>
        </div>,
        <div
          key="2"
          className="flex h-full flex-col text-teal lg:ml-[55px] lg:flex-row lg:items-center"
        >
          <BigNumber>2</BigNumber>
          <div className="mx-auto text-mono-white lg:mx-[40px] lg:my-auto lg:max-w-[224px]">
            <BodyRegular>
              Our app uses behavioural science to retrain your mind to make
              healthy choices automatically, so these stick in the long run.
            </BodyRegular>
          </div>
        </div>,
        <div
          key="3"
          className="flex h-full flex-col text-teal lg:ml-[55px] lg:flex-row lg:items-center"
        >
          <BigNumber>3</BigNumber>
          <div className="mx-auto text-mono-white lg:mx-[40px] lg:my-auto lg:max-w-[224px]">
            <BodyRegular>
              By building healthier habits that become second nature, losing
              weight feels easier and lasts longer.
            </BodyRegular>
          </div>
        </div>,
      ]}
    />
  </div>
);
