import { BigNumber } from '@common/BigNumber';
import { BodyRegular } from '@common/body';
import { H2 } from '@common/headline';
import BmjJournals from '@common/Icons/BmjJournals';
import Trustpilot from '@common/Icons/Trustpilot';
import TrustpilotRating from '@common/Icons/TrustpilotRating';
import { SeparatedGrid } from './SeparatedGrid';

export const Details = (): JSX.Element => (
  <div className="min-w-full bg-blue pb-6 pt-14 text-center lg:pb-[134px] lg:pt-20">
    <SeparatedGrid
      heading={
        <div className="flex items-center justify-center pb-6">
          <div className="w-[760px] text-mono-white">
            <H2>
              Let{"'"}s make this decision{' '}
              <span className="text-teal">easy for you</span>
            </H2>
          </div>
        </div>
      }
      content={[
        <div key="1" className="text-teal lg:text-left">
          <BigNumber>2x</BigNumber>
          <div className="mx-auto max-w-[336px] text-mono-white lg:mx-0">
            <BodyRegular>
              We{"'"}re twice as effective as other weight-loss programmes, as
              shown by a groundbreaking national trial run by the NHS.
            </BodyRegular>
          </div>
          <div className="mx-auto">
            <BmjJournals className="mx-auto mt-6 lg:mx-0" />
          </div>
        </div>,
        <div key="2">
          <div className="mt-8 text-teal lg:mt-0 lg:text-left">
            <BigNumber>90%</BigNumber>
            <div className="mx-auto max-w-[368px] text-mono-white lg:mx-0">
              <BodyRegular>
                of Trustpilot reviews rate us as {"'"}excellent{"'"} because 9
                out of 10 people lose weight and keep it off, even after 12
                months.
              </BodyRegular>
            </div>
            <div className="mt-6 flex flex-col-reverse lg:flex-row">
              <TrustpilotRating className="mx-auto mt-[15px] lg:mx-0 lg:mr-[15px] lg:mt-0" />
              <Trustpilot className="mx-auto lg:mx-0" />
            </div>
          </div>
        </div>,
      ]}
    />
  </div>
);
