import Image from 'next/legacy/image';

import { BodyLarge, BodyRegular } from './body';

import channel4 from '@public/img/icons/references/channel4.svg';
import eveningStandard from '@public/img/icons/references/eveningStandard.svg';
import metro from '@public/img/icons/references/metro.svg';
import theGuardian from '@public/img/icons/references/theGuardian.svg';
import theSundayTelegraph from '@public/img/icons/references/theSundayTelegraph.svg';
import theTimes from '@public/img/icons/references/theTimes.svg';

export const ReferencesRibbon = (): JSX.Element => (
  <div className="min-w-full bg-grey-600">
    <div className="container m-auto flex h-[512px] flex-col py-12 lg:h-24 lg:flex-row lg:py-0">
      <div className="flex flex-row justify-center pb-[18px] lg:flex-col lg:pb-0">
        <div className="text-mono-grey lg:hidden">
          <BodyLarge>As seen on</BodyLarge>
        </div>
        <div className="hidden whitespace-nowrap pl-[60px] pr-2 text-mono-grey lg:inline-block">
          <BodyRegular>As seen on</BodyRegular>
        </div>
      </div>
      <div className="flex h-[368px] flex-col items-center justify-evenly lg:h-24 lg:w-full lg:flex-row">
        <Image src={theGuardian} alt="The Guardian" width={140} height={40} />
        <Image src={theTimes} alt="The Times" width={210} height={28} />
        <Image src={channel4} alt="Channel 4" width={30} height={30} />
        <Image
          src={theSundayTelegraph}
          alt="The Sunday Telegraph"
          width={250}
          height={31}
        />
        <div className="flex items-center lg:hidden xl:flex">
          <Image
            src={eveningStandard}
            alt="Evening Standard"
            width={220}
            height={29}
          />
        </div>
        <Image src={metro} width={80} alt="Metro" height={23} />
      </div>
    </div>
  </div>
);
