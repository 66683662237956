import { TakeOurQuizCTA } from '../Hero/TakeOurQuizCTA';

import { MedicationStockBanner } from './MedicationStockBanner';
import { HeroMikeAndChris } from '../Hero/HeroMikeAndChris';

import HeroImage from '@public/img/home-page/hero-chris-and-mike.png';

type Props = {
  utmCampaign: string;
  utmMedium: string;
  location: string;
};

export const HomepageHero = ({
  utmMedium,
  utmCampaign,
  location,
}: Props): JSX.Element => (
  <HeroMikeAndChris
    title={
      <div className="md:mt-8">
        <span className="text-mono-white">
          {'"'}One new habit that{' '}
          <span className="text-blue">lasts for a lifetime</span> is worth 100
          short-term diets that don{"'"}t stick.{'"'}
        </span>
      </div>
    }
    topContent={
      <MedicationStockBanner utmCampaign={utmCampaign} utmMedium={utmMedium} />
    }
    subtitle={
      <span className="text-blue-800">
        Mike and Chris, founders of Second Nature
      </span>
    }
    description={
      <span className="text-mono-white">
        By focusing on creating new habits, Second Nature makes weight loss feel
        easier and last longer.
      </span>
    }
    image={HeroImage}
    altText="Chris and Mike"
    color="bg-salmon"
    placeholder="blur"
    priority
    ctaChildren={
      <div className="whitespace-nowrap pb-60 md:pb-0">
        <TakeOurQuizCTA
          utmMedium={utmMedium}
          utmCampaign={utmCampaign}
          location={location}
          utmContent="homepage_top_quiz"
        />
      </div>
    }
  />
);
