import Image, { StaticImageData } from 'next/legacy/image';

import { BodySmall } from '@common/body';
import { H4, H6 } from '@common/headline';
import { InputLabel } from '@common/inputLabel';
import { TagTitle } from '@common/tagTitle';
import { TakeOurQuizCTA } from '@components/Hero/TakeOurQuizCTA';

type Props = {
  glp1MedicationIncluded: string;
  image: StaticImageData;
  altText: string;
  priceHeading: string;
  priceSubheading?: string;
  weightLossPercentage: string | JSX.Element;
  utmMedium?: string;
  utmCampaign?: string;
  medicationType?: 'Wegovy' | 'Mounjaro' | 'Wegovy or Mounjaro';
};

export const ProgrammeCardHeaderImage = ({
  glp1MedicationIncluded,
  image,
  altText,
}: {
  glp1MedicationIncluded: string;
  image: StaticImageData;
  altText: string;
}): JSX.Element => (
  <div className="relative block h-[284px]">
    <Image layout="fill" objectFit="cover" src={image} alt={altText} />
    <div className="absolute bottom-6 left-6">
      <div className="flex">
        {glp1MedicationIncluded === 'Yes' && (
          <div className="mr-2 rounded-lg bg-teal-200 p-2 text-teal">
            <TagTitle>Weekly injection</TagTitle>
          </div>
        )}
        <div className="rounded-lg bg-dawn-blue-200 p-2 text-dawn-blue">
          <TagTitle>Weight-loss programme</TagTitle>
        </div>
      </div>
    </div>
  </div>
);

export const ProgrammeCard = ({
  glp1MedicationIncluded,
  image,
  altText,
  priceHeading,
  priceSubheading = '',
  weightLossPercentage,
  utmMedium,
  utmCampaign,
  medicationType = 'Wegovy',
}: Props): JSX.Element => (
  <div className="h-[629px] w-[351px] overflow-hidden rounded-3xl bg-mono-white lg:h-[636px] lg:w-[424px]">
    <ProgrammeCardHeaderImage
      altText={altText}
      glp1MedicationIncluded={glp1MedicationIncluded}
      image={image}
    />

    <div className="p-6 pb-8 text-blue">
      <div className="flex justify-between border-b border-dawn-blue-200 pb-3">
        <BodySmall>Average weight loss after 12 months</BodySmall>
        <H6>{weightLossPercentage}</H6>
      </div>
      <div className="flex justify-between border-b border-dawn-blue-200 py-3">
        <BodySmall>{medicationType} medication included</BodySmall>
        <H6>{glp1MedicationIncluded}</H6>
      </div>
      <div className="flex justify-between border-b border-dawn-blue-200 py-3">
        <BodySmall>Ongoing 1-1 support from a nutritionist</BodySmall>
        <H6>Yes</H6>
      </div>
      <div className="mt-8 flex flex-col items-center">
        <H4>{priceHeading}</H4>
        {priceSubheading && (
          <div className="mt-1">
            <InputLabel>{priceSubheading}</InputLabel>
          </div>
        )}
        <div className="mt-5">
          <TakeOurQuizCTA
            ctaText={
              glp1MedicationIncluded === 'Yes'
                ? 'Check my eligibility'
                : 'Take our quiz'
            }
            location="Programme card"
            utmCampaign={utmCampaign}
            utmMedium={utmMedium}
          />
        </div>
      </div>
    </div>
  </div>
);
