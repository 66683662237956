import { GetStaticProps } from 'next';

import { HomeTranslationType } from '@nextTypes/translations';

import Meta from '@components/Meta';

import { ChangeYourMindHero } from '@components/ChangeYourMindHero/ChangeYourMindHero';
import { Details } from '@components/SeparatedGrid/Details';
import { Positives123 } from '@components/SeparatedGrid/Positives123';
import { ProgrammeSelection } from '@components/Homepage/ProgrammeSelection';
import { ReferencesRibbon } from '@common/ReferencesRibbon';
import { Testimonial } from '@components/Testimonial/Testimonial';
import { Footer } from '@components/Footer/Footer';

import { getStaticTranslationProps } from '@nextUtils/translations';

import { SignPost } from '../common/SignPost';
import { HomepageHero } from '../components/HomepageHero/HomepageHero';

export const getStaticProps: GetStaticProps =
  getStaticTranslationProps<HomeTranslationType>('Home');

type Props = {
  utmCampaign: string;
  utmMedium: string;
};

const Homepage = ({ utmCampaign, utmMedium }: Props): JSX.Element => (
  <>
    <Meta
      title="Second Nature - Make losing weight feel easier"
      metaDescription="Second Nature exists to change minds about losing weight. Our app uses behavioural science to retrain your mind to make healthy choices automatically, so these stick in the long run"
      relativeCanonicalURL="/"
    />
    <div className="flex flex-col">
      <div className="order-1">
        <HomepageHero
          location="Home"
          utmMedium={utmMedium}
          utmCampaign={utmCampaign}
        />
      </div>
      <div className="order-9 lg:order-2">
        <ReferencesRibbon />
      </div>
      <div className="order-3">
        <ProgrammeSelection utmMedium={utmMedium} utmCampaign={utmCampaign} />
      </div>
      <div className="order-4">
        <Testimonial />
      </div>
      <div className="order-5">
        <Details />
      </div>
      <div className="order-6">
        <ChangeYourMindHero />
      </div>
      <div className="order-7">
        <Positives123 />
      </div>
      <div className="order-8">
        <SignPost version="A" utmMedium={utmMedium} utmCampaign={utmCampaign} />
      </div>
      <div className="order-9">
        <Footer />
      </div>
    </div>
  </>
);

export default Homepage;
