import { H2Small } from '../../common/headline';
import { BodyRegular } from '../../common/body';

import { TestimonialCard } from '../Card/TestimonialCard';

import alison from '@public/img/people/alison.png';
import jordan from '@public/img/people/jordan.png';
import leigh from '@public/img/people/restarter-user.png';
import ruth from '@public/img/people/ruth.png';

export const Testimonial = (): JSX.Element => (
  <div className="flex min-w-full flex-col items-center bg-dawn-blue px-3 pb-20 pt-14 lg:container lg:m-auto lg:px-[60px] lg:pb-[95px] lg:pt-[100px]">
    <div className="flex w-full justify-center">
      <div className="text-center text-blue lg:mr-6 lg:max-w-[484px]">
        <div className="lg:hidden">
          <H2Small>They{"'"}ve done it. And </H2Small>
          <H2Small>
            <span className="text-mono-white">you can</span> too.
          </H2Small>
        </div>
        <div className="hidden lg:block">
          <H2Small>They{"'"}ve done it.</H2Small>
          <H2Small>
            And <span className="text-mono-white">you can</span> too.
          </H2Small>
        </div>
        <div className="max-w-[351px] pt-4 lg:max-w-none lg:pt-6">
          <BodyRegular>
            Second Nature makes losing weight feel easier. But don{"'"}t take
            our word for it, here{"'"}s what our members say about us:
          </BodyRegular>
        </div>
      </div>
    </div>
    <div className="mt-12 flex w-full max-w-[1320px] justify-center lg:mt-[80px]">
      <div className="hidden xxl:mr-6 xxl:block">
        <TestimonialCard
          description="Been on the programme 5 weeks and have lost 13 pounds so far. It's really easy to follow, not restrictive, I don’t feel hungry and the recipes are delicious. It's what it says - not a diet but a life change. At first, it does seem a little expensive but worth it and I could easily spend the monthly fee on one take away for my family of 4!"
          duration="5 weeks"
          headline="Great programme"
          location="Brighton"
          name="Alison"
          weightLoss="- 13 lbs"
          profilePicture={alison}
        />
      </div>
      <div className="hidden lg:mr-6 lg:block">
        <TestimonialCard
          description="Second Nature is not a fad diet it has been a total transformation of my mindset, my relationship with food has completely shifted to an experience of nourishing my body and the day to day support has been incredible. I would highly recommend this diet over any other!"
          duration="3 months"
          headline="I was somewhat apprehensive"
          location="Kent"
          name="Jordan"
          weightLoss="- 13 lbs"
          profilePicture={jordan}
        />
      </div>
      <div className="hidden lg:block">
        <TestimonialCard
          description="Second Nature is helping me to understand my overall health. The support from the coaches is amazing, and also from the group. I am learning to understand the science and psychology behind how I eat and why I eat. This isn't a quick fix but a programme to help me to live a healthier life."
          duration="6 weeks"
          headline="Second Nature is helping me to understand myself and my habits better…"
          location="Cambridge"
          name="Ruth"
          weightLoss="- 8 lbs"
          profilePicture={ruth}
        />
      </div>
      <div className="lg:hidden">
        <TestimonialCard
          description="The Second Nature programme has been life changing for me. It's not just about losing weight, it has also helped me make changes that are healthy and sustainable, and made me feel better about myself."
          duration="10 weeks"
          headline="I don't even know where to start!"
          location="London"
          name="Leigh"
          weightLoss="- 11 lbs"
          profilePicture={leigh}
        />
      </div>
    </div>
  </div>
);
