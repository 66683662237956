export type SeparatedGridProps = {
  content: React.ReactNode[];
  heading?: React.ReactNode;
  borderColour?: string;
};

const separator = (borderColour = 'border-purple') => (
  <div
    className={`h-0 w-full border-t-[1.5px] lg:h-full lg:w-0 lg:border-r-[1.5px] lg:border-t-0 ${borderColour} rounded-full`}
  ></div>
);

export const SeparatedGrid = ({
  content,
  heading,
  borderColour,
}: SeparatedGridProps): JSX.Element => {
  const columns = content.length || 1;
  return (
    <div className="container m-auto h-full">
      {heading && <div className="px-4 lg:pb-[76px]">{heading}</div>}
      <div
        className={`sn-grid flex grid-cols-1 flex-col lg:flex-row lg:grid-cols-${columns}`}
      >
        {content.map((element, i) => (
          <div
            className={`relative flex w-full flex-col place-content-center justify-center lg:flex-row`}
            key={i}
          >
            <div className="mb-8 lg:mb-0">{element}</div>
            {i < content.length - 1 && (
              <div className="flex h-0 w-full lg:absolute lg:right-0 lg:h-full lg:w-0">
                {separator(borderColour)}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
