import { SVGProps } from 'react';

const TrustpilotRating = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 30"
    width={160}
    height={30}
    fill="none"
    {...props}
  >
    <path
      d="M30 0H0v30h30V0ZM62.5 0h-30v30h30V0ZM95 0H65v30h30V0ZM127.5 0h-30v30h30V0Z"
      fill="#00B67A"
    />
    <path d="M160 0h-30v30h30V0Z" fill="#00B67A" />
    <path d="M149 0h-19v30h19V0Z" fill="#00B67A" />
    <path
      d="m14.999 20.219 4.562-1.157 1.907 5.875-6.469-4.718Zm10.5-7.594h-8.031l-2.469-7.563-2.469 7.563H4.5l6.5 4.688-2.469 7.562 6.5-4.688 4-2.875 6.469-4.687ZM47.5 20.219l4.563-1.157 1.906 5.875L47.5 20.22ZM58 12.625h-8.031L47.5 5.062l-2.469 7.563H37l6.5 4.688-2.469 7.562 6.5-4.688 4-2.875L58 12.625ZM80 20.219l4.562-1.157 1.906 5.875L80 20.22Zm10.5-7.594h-8.032L80 5.062l-2.468 7.563h-8.032l6.5 4.688-2.468 7.562 6.5-4.688 4-2.875 6.468-4.687ZM112.499 20.219l4.563-1.157 1.906 5.875-6.469-4.718Zm10.5-7.594h-8.031l-2.469-7.563-2.469 7.563h-8.031l6.5 4.688-2.469 7.562 6.5-4.688 4-2.875 6.469-4.687ZM145 20.219l4.563-1.157 1.906 5.875L145 20.22Zm10.5-7.594h-8.031L145 5.062l-2.469 7.563H134.5l6.5 4.688-2.469 7.562 6.5-4.688 4-2.875 6.469-4.687Z"
      fill="#fff"
    />
  </svg>
);

export default TrustpilotRating;
