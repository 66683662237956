import { ReactNode } from 'react';

export const TagTitle = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => (
  <label className="m-0 font-cera text-[14px] font-medium leading-[14px] tracking-[-0.22px]">
    {children}
  </label>
);
