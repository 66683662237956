import Image, { StaticImageData } from 'next/legacy/image';

import { BodyRegular, BodySmall } from '@common/body';
import { H3, H4, H5, H6 } from '@common/headline';
import Icon from '@common/Icons/Icon';

type Props = {
  description: string;
  duration: string;
  headline: string;
  location: string;
  name: string;
  profilePicture: StaticImageData;
  weightLoss: string;
};

export const TestimonialCard = ({
  description,
  duration,
  headline,
  location,
  name,
  profilePicture,
  weightLoss,
}: Props): JSX.Element => (
  <div className="flex h-[380px] w-[351px] flex-col rounded-3xl bg-mono-white p-6 lg:w-[424px] lg:p-8">
    <div className="flex-1 flex-col">
      <div className="flex flex-col lg:flex-row">
        <div className="mb-6 flex flex-row lg:mb-6">
          <div className="mr-4 h-[56px] w-[56px] rounded-full">
            <Image
              src={profilePicture}
              height={56}
              width={56}
              alt={`${name} profile picture`}
            />
          </div>
          <div className="flex flex-col text-blue">
            <H3>{name}</H3>
            <div className="lg:hidden">
              <BodySmall>{location}</BodySmall>
            </div>
            <div className="hidden lg:block">
              <BodyRegular>{location}</BodyRegular>
            </div>
          </div>
        </div>
        <div className="lg:ml-auto lg:pt-[10px]">
          <div className="mb-2 flex h-6 w-[136px] flex-row justify-evenly">
            {[...Array(5)].map((x, i) => (
              <div className="h-6 w-6 fill-salmon stroke-salmon" key={i}>
                <Icon name="star" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-4 text-blue">
        <div className="lg:hidden">
          <H4>{headline}</H4>
        </div>
        <div className="hidden lg:block">
          <H5>{headline}</H5>
        </div>
      </div>
      <div className="mb-6 text-blue">
        <BodySmall>{description}</BodySmall>
      </div>
    </div>
    <div className="justify-end">
      <div className="flex flex-row items-center">
        <div className="mr-2 fill-blue stroke-blue">
          <Icon name="stopwatch" />
        </div>
        <div className="mr-8 text-blue">
          <div className="lg:hidden">
            <H5>{duration}</H5>
          </div>
          <div className="hidden lg:block">
            <H6>{duration}</H6>
          </div>
        </div>
        <div className="mr-2 fill-blue stroke-blue">
          <Icon name="scale" />
        </div>
        <div className="text-blue">
          <div className="lg:hidden">
            <H5>{weightLoss}</H5>
          </div>
          <div className="hidden lg:block">
            <H6>{weightLoss}</H6>
          </div>
        </div>
      </div>
    </div>
  </div>
);
