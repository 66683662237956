type Props = {
  backgroundColour?: string;
  children: React.ReactNode;
};

export const Section = ({ backgroundColour, children }: Props): JSX.Element => (
  <section
    className={`sn-grid mx-0 grid-cols-1 ${backgroundColour} min-w-full`}
  >
    {children}
  </section>
);

Section.defaultProps = {
  backgroundColour: 'bg-salmon',
};
