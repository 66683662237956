import { ReactNode } from 'react';

export const InputLabel = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => (
  /**
   * The line height (leading) set here is not being applied as expected. I
   * think there is a clash with other/global <label/> styling.
   */
  <label className="m-0 font-cera text-[13px] font-normal leading-[14px] tracking-[-0.22px]">
    {children}
  </label>
);
